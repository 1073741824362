<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row hide-print">
                <div class="col-xs-12">
                    <h1 class="h2 margin-bottom-30">Nuova Prenotazione</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-5">
                    <BookingForm @reservation-data-changed="reservationDateChanged" :document-type="newReservation.document_type"></BookingForm>
                </div>
                <div class="col-xs-6 col-xs-offset-1">
                    <div class="panel panel-default">
                        <div class="panel-heading">Cassa</div>
                        <div class="panel-body">
                            <CashSummaryTable
                                    :estimate-reservation="estimateReservation"
                            ></CashSummaryTable>
                        </div>
                    </div>

                    <div class="panel panel-default">
                        <div class="panel-heading">Tipo Documento</div>
                        <div class="panel-body">
                            <select class="form-control rate_option" v-model="newReservation.document_type" @change="onChange">
                                <option v-for="(type, index) in documentTypeOptions" :value="type.value" :key="index" >
                                    {{type.name}}
                                </option>
                            </select>
                            <div v-if="newReservation.document_type === Keys.DOCUMENT_TYPES.INVOICE" style="margin-top: 20px">
                                <span>N. Fattura</span>

                                <input type="number" class="form-control" id="fname" v-model="newReservation.invoice_number">
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading">Modalità Pagamento</div>
                        <div class="panel-body">
                            <p class="alert alert-info" v-show="estimateReservation.price_final === 0" id="no-payment-required">Non è richiesto nessun pagamento</p>
                            <div class="radio" v-for="paymentMethod in paymentOptions" :key="paymentMethod.value">
                                <label>
                                    <input type="radio" :disabled="estimateReservation.price_final === 0" name="optionsPayments" id="cash" :value="paymentMethod.value" v-model="newReservation.payment_mode">
                                    {{paymentMethod.name}}
                                </label>
                            </div>
                            <button id="pay_now_btn" type="button" class="btn btn-lg btn-block btn-primary" @click="createReservation()">
                                <span v-if="estimateReservation.price_final === 0">Crea prenotazione</span>
                                <span v-else>Paga ora €{{estimateReservation.price_final.toFixed(2)}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <GenericChooseModal code="1" title="Informazione" :danger-message="true" message="Devi selezionare almeno un sito per poter creare una prenotazione" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalReservationItemError" @positive-click-generic-modal1="visibleModalReservationItemError = false" @close-generic-modal1="visibleModalReservationItemError = false"/>
      <GenericChooseModal code="2" title="Informazione" :danger-message="true" message="Devi selezionare la nazione prima di continuare" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalCountryError" @positive-click-generic-modal2="visibleModalCountryError = false" @close-generic-modal2="visibleModalCountryError = false"/>
        <GenericChooseModal code="3" title="Informazione" :danger-message="true" message="Numero Fattura già esistente" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalSameInvoiceNumber" @positive-click-generic-modal3="visibleModalSameInvoiceNumber = false" @close-generic-modal3="visibleModalSameInvoiceNumber = false"/>
        <GenericChooseModal code="4" title="Informazione" :danger-message="true" message="Ricordati di compilare tra i dati cliente: Partita Iva, PEC, Indirizzo" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalInvoiceParameterMisses" @positive-click-generic-modal4="visibleModalInvoiceParameterMisses = false" @close-generic-modal4="visibleModalInvoiceParameterMisses = false"/>

    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import CashSummaryTable from "../components/Booking/CashSummaryTable";
    import baseMixin from "../common/baseMixin";
    import {createReservation, estimateReservation, getInvoiceNumber} from "../api";
    import GenericChooseModal from "../components/modal/GenericChooseModal";
    import BookingForm from "../components/Booking/BookingForm";


    export default {
        title: "Nuova Prenotazione",
        name: "Booking",
        components: {
            BookingForm, CashSummaryTable, ContainerWithMenu,GenericChooseModal},
        mixins: [baseMixin],
        data: () => {
            return {
                loading: false,
                paymentOptions: [],
                documentTypeOptions: [],
                newReservation: {
                    reservation_items: [],
                    first_name: null,
                    email: null,
                    last_name: null,
                    phone_number: null,
                    payment_mode: null,
                    country: null,
                    date: null,
                    document_type: 1,
                    invoice_number: null,
                    discount: 0,
                    customer_vat_number: null,
                    customer_pec: null,
                    customer_address: null,
                    customer_recipient_code: null
                },
                estimateReservation: {
                    price: 0,
                    price_final: 0,
                    price_discount: 0,
                    reservation_item_grouped_by_product: {},
                    discount_people: false
                },
                visibleModalCountryError: false,
                visibleModalSameInvoiceNumber: false,
                visibleModalReservationItemError: false,
                visibleModalInvoiceParameterMisses: false
            }
        },
        methods: {
            async reservationDateChanged (newReservationObj) {
                if (newReservationObj.reservation_items !== this.newReservation.reservation_items || newReservationObj.discount !== this.newReservation.discount) {
                    try {
                        this.estimateReservation = await estimateReservation(newReservationObj.reservation_items,newReservationObj.discount);
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.newReservation.reservation_items = newReservationObj.reservation_items;
                this.newReservation.first_name = newReservationObj.first_name;
                this.newReservation.email = newReservationObj.email;
                this.newReservation.last_name = newReservationObj.last_name;
                this.newReservation.phone_number = newReservationObj.phone_number;
                this.newReservation.country = newReservationObj.country;
                this.newReservation.date = newReservationObj.date;
                this.newReservation.discount = newReservationObj.discount
                this.newReservation.customer_vat_number = newReservationObj.customer_vat_number
                this.newReservation.customer_pec = newReservationObj.customer_pec
                this.newReservation.customer_address = newReservationObj.customer_address
                this.newReservation.customer_recipient_code = newReservationObj.customer_recipient_code
                console.log(this.newReservation.discount)
            },
            async createReservation() {
                this.loading = true;
                try {
                    let responseReservation = await createReservation(
                        this.newReservation.first_name,
                        this.newReservation.last_name,
                        this.newReservation.email,
                        this.newReservation.phone_number,
                        this.newReservation.country,
                        this.newReservation.payment_mode,
                        this.newReservation.reservation_items,
                        this.newReservation.document_type,
                        this.newReservation.invoice_number,
                            this.newReservation.customer_vat_number,
                            this.newReservation.customer_pec,
                            this.newReservation.customer_address,
                            this.newReservation.customer_recipient_code,
                        this.newReservation.date,
                        this.newReservation.discount,


                    );
                    this.$router.push({ name:'reservation_detail', params: {reservation_id: responseReservation.reservation_id}});
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    if(e.response.data.code === "reservation_items_required"){
                      this.visibleModalReservationItemError = true
                    }
                    else if (e.response.data.code === "country_required"){
                      this.visibleModalCountryError = true
                    }
                    else if(e.response.data.code === "same_invoice_found"){
                        this.visibleModalSameInvoiceNumber = true
                        this.$route.params.successMessage = null
                        this.$route.params.errorMessage = null
                    }
                    else if(e.response.data.code === "invoice_parameters_misses"){
                        this.visibleModalInvoiceParameterMisses = true
                        this.$route.params.successMessage = null
                        this.$route.params.errorMessage = null
                    }
                }
            },
            async getInvoiceNumber() {
                this.loading = true;
                try {
                    let result = await getInvoiceNumber();
                    this.newReservation.invoice_number = result.invoice_number
                    console.log(result.invoice_number)
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                }
            },
            onChange() {
                if(this.newReservation.document_type === this.Keys.DOCUMENT_TYPES.INVOICE) {
                    this.getInvoiceNumber()
                }
            },
        },
        mounted() {
            this.paymentOptions = this.Keys.PAYMENT_MODE_WITHOUT_CREDIT_CARD_OPTIONS();
            this.newReservation.payment_mode = this.Keys.PAYMENT_MODES.CASH;
            this.documentTypeOptions = this.Keys.DOCUMENT_TYPE_OPTIONS();
        }
    };
</script>
