<template>
    <ModalContainer :visible="visible">
        <template v-slot:header>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close-generic-modal' + code)"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">{{title}}</h4>
        </template>
        <template v-slot:body>
        <div
              v-if="dangerMessage"
              class="alert alert-danger"
              id="modal-error-message"
              role="alert"
        >
          {{message}}
        </div>
          <div v-else>
            {{message}}
          </div>
        </template>

        <template v-slot:footer>
            <a v-bind:class="{'btn-danger': danger, 'btn-primary': !danger}" class="btn btn-lg btn-block margin-bottom-10" @click.prevent.stop="$emit('positive-click-generic-modal' + code)" id="cancel_reservation_btn">{{positiveButtonText}}</a>
            <a v-if="!hideNegativeButton" class="btn btn-default btn-lg btn-block" href="" @click.prevent.stop="$emit('negative-click-generic-modal' + code)">{{negativeButtonText}}</a>
        </template>
    </ModalContainer>
</template>

<script>
    import ModalContainer from "./ModalContainer";

    export default {
        name: "GenericChooseModal",
        components: {ModalContainer},
        props: {
            code: {
                type: String,
                default: ""
            },
            visible: {
                type: Boolean,
                required: true
            },
            danger: {
                type: Boolean,
                default: false
            },
            dangerMessage: {
                type: Boolean,
              default: false
            },
            title: {
                type: String,
                required: true
            },
            message: {
                type: String,
                required: true
            },
            positiveButtonText: {
                type: String,
                default: "Si"
            },
            negativeButtonText: {
                type: String,
                default: "No"
            },
            hideNegativeButton: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
